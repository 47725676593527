import Button from '@mui/material/Button';
import DoneAllSharpIcon from '@mui/icons-material/DoneAllSharp';

const ButtonsLoginComponent = () => {
    const btnEnter = {
        backgroundColor:'#1976d2',
    "&:hover": {
      backgroundColor: '#1565c0'
    }
      };

      const btnSolict = {
        backgroundColor:'#8e8f8d',
        "&:hover": {
          backgroundColor: '#8f8d8db3'
        }
      };



    return(
        <div>
              <Button
                startIcon={<DoneAllSharpIcon/>}
                type="submit" 
                variant="contained" 
                fullWidth
                color="success" 
                size="medium" 
                className="mb-3 mb-md-4 md-4" 
                sx={btnEnter}
                >
            Entrar
            </Button>

            <br/>
            <Button
                type="button" 
                variant="contained" 
                fullWidth
                color="primary" 
                size="medium" 
                className="mb-3 mb-md-4 md-4" 
                sx={btnSolict}
                >
                Solicitar Acesso
            </Button>
        </div>
    );
};

export default ButtonsLoginComponent;