import axios from "axios";

const END_POINT = process.env.REACT_APP_END_POINT

export const api = axios.create({
    baseURL: END_POINT,
})

export const createSession = async (username,password) => {
    return api.post('/api-token-auth/',{username,password})
}

export const getTicketsNumber = async(ticket) => {
    return api.get(`/buscatickets/${ticket}`)
}

export const getTickets = async() => {
    return api.get(`/tickets`)
}

export const getPatios = async() => {
    return api.get(`/patios`) 
}

export const putValidaTickets = async(ticket,data) => {
    console.log(data)
    return api.put(`/tickets/${ticket}/`,data)
}