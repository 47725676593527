import Typography from '@mui/material/Typography';

const FooterLoginComponent = () => {

    return(
        <div>
            <Typography className="text-center" variant="h6">
                        Version: 1.2
            </Typography>
        </div>
    );
};

export default FooterLoginComponent;